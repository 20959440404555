
.card{
    position: relative;
    border-radius: 0.5rem;
    border: solid 2px #1d5a87 ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 200ms ease-in;
    padding: 5px;
    background-color:white;
    color: black;
    width: 500px;
    height: 380px;
    background-color:white;
    color: black;
    margin: 20px;
}

.card-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:0px;
    padding: 0px;
}

.card-image{
    width: 100%;
    height: 15.5rem;
    object-fit: cover;
    border-radius: 0.5rem;
}

.card-title {
    padding-top: 0.5rem;
}

.card-btn{
    padding: 0.5rem;
    width: 100%;
    border-radius: 0.4rem;
}

.card:hover{
    transform: scale(1.02);
    border: solid 1px;
}

.card:hover .card-btn{

    background-color:#1d5a87 ;
    color: rgb(255, 255, 255);

}