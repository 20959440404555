/* Image.css */
.image {
  width: 100%;
  position: relative;
  animation: fading 2s;
}

.mainImg {
  width: 100%; 
  height: 90vh;
  object-fit: cover; 
}

.imageBg{
  width: 90%;
  height: 80%;
  text-align: center; 
    position: absolute; 
    top: 45%; 
    left: 50%;
    transform: translate(-50%, -50%); 
    opacity: 0.7;
}


@keyframes fading {
  0% {
      opacity: 0
  }

  100% {
      opacity: 1
  }
}