/* Estilos para el logo */
.logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    width: 60px; 
    height: 60px; 
    margin-right: 10px;
    background-color: aliceblue;
    border-radius: 50%;
  }
  