.generalBody{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
}

.carouselCard{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.generalCarousel{
    padding: 50px;
}

.generalCarouselCard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 26%;
}

.listOfCards{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 15px;
    margin-top: 20px;}


@media all and (max-width:1100px) {
    .card{
        margin:0px
    }

    .card img{
        height: 9.8rem;
    }

    .carousel img{
        height: 30rem;
    }

    .cardLine{
        height: 40%;
        margin: 60px 0px;
    }

    .cardLine img{
        height: 9.8rem;
    }


}

@media all and (max-width: 700px) {


    .carouselCard{
        flex-direction: column;
        align-items: center;
    }
    .carousel{
        margin-bottom: 20px;
    }

    .generalCarouselCard{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }

    .cardLine img{
        height: 8rem;
    }


}