/* Estilos para el título del proyecto */
.title {
  /* border: solid 2px #F8F617 ;
  padding: 0.2rem; */
  }

  .titleWord{
    font-size: 22px;
    color:white;
    margin: 0; 
    font-weight: bold;
    padding: 0.1rem;
    font-family: 'DM Serif Display', serif;

  }

  
  @media (max-width:1200px){
    .title{
      font-size: 35px;
    }
  }