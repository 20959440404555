*{
  font-family: 'Times New Roman', Times, serif;
}
html {
  margin: 0px;
  height: 100%;
  width: 100%;
}


body {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  min-height: 100%;
  width: 100%;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  background-color:#def2ff;
}