
.footer {
  background-color: #333333;
  color: #fff;
  padding: 10px;
  bottom: 0px;
}

.custom-bg-color {
  background-color: #1D5A87
}

a .text-white{
  color: white;
  text-decoration: none;
}
a .text-white:hover {
  color: #0783FF; /* Yellow color on hover  */
}

i {
  margin-right: 8px;
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.col {
  text-align: center;
  padding: 15px;
}
.col + .col {
  margin-top: 50px;
}

@media (max-width: 768px) {
  .footer {
    background-color: #555;
  }
}