.carousel{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 63%;
}


.slide{
    border-radius: 0.5rem;
    border: solid 2px #1d5a87 ;
    background-color: white;
    color: black;
    padding: 5px;
    width: 100%;

}

.slide-hidden {
    display: none;
}

.textContain{
 position:relative;
 
 
}

.carousel img{
   border-radius: 0.5rem;
   width: 100%;
   object-fit: cover;
   height: 45rem; 
}

.arrow{
    position:absolute;
    width: 2rem;
    height: 2rem;
    color: white ;
    filter: drop-shadow(0px 0px 5px #555);

}

.arrow:hover {
    cursor: pointer;
}

.arrow-left{
    left: 0.5rem;
}

.arrow-right{
    right: 0.5rem;
}

.indicators{
    display: flex;
    position: absolute;
    bottom: 1rem;
}

.indicator{
    background-color: white;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
}
.indicator-inactive {
    background-color: grey;
}

@media all and (max-width: 700px) {
   .carousel{
    width: 90%;
   }
}