    .bigCard{
        /* margin: 1.5rem 14rem; */

        margin: 4rem auto;
        width: 55%;
    }
    
    .bigCard-body{
        background-color: white;
        padding: 10px;
        border-radius: 0.5rem;
        position: center;
        
    }
    .bigCard-body p {
        margin: 0;
    }

   .bigCard img {
        border-radius: 0.5rem;
        object-fit: cover;
        width: 100%;
    }

    .bigCard-body button{
        border-radius: 0.5rem;
    }

