/* Estilos para el encabezado */


.header{
  position: sticky;
  top: 0;
  z-index: 99;
}

.navbar {
  background-color:#1d5a87 !important; 
  /* background-image: url("./istockphoto-1318638218-640x640.png"); */
}

.navbar a{

  color: #fff !important;
  font-size: 1.7rem;
  margin: 0rem 0.5rem;
}

.dropdown-menu{
  background-color:#1d5a87 !important; 
}

.dropdown-item:hover{
  background-color: cadetblue;
}

