.aboutUs-container {
    background-image: url('./aboutus.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px; 
    min-height: 80vh;
    position: relative;
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-between;
}

.text-section {
    display: inline-flex; 
    flex-direction: column;
    background-color: #DEF2FF;
    border-radius: 25px;
    border: 5px solid rgba(0, 0, 0, 0.5);
    padding: 30px; 
    transition: all 0.3s ease;
    margin: 20px; 
    width: 400px; /* O podrías especificar un ancho máximo, por ejemplo, max-width: 350px; */
    height: 200px; /* Ajusta a un valor específico o usa min-height para un espacio vertical mínimo */
    align-items: center; /* Alineación de los items al centro */
}


.text-section h2 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;  /* Títulos centrados */
    text-decoration: none;  /* Títulos sin subrayado */
}


.text-section p {
    font-size: 18px;
    line-height: 1.6;
    word-wrap: break-word; 
    max-width: 400px;
    margin: 10px 0;
}

.text-section:hover {
    background-color:white  ;
    transform: scale(1.05);
    color: black ;
}