

form {
  max-width: 500px;
  margin: auto;
  text-align: center;
  padding: 20px;
  background-color: white;
}

.ccontainer{
  background-color: white;
}

input[type="text"], input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid white;
  box-sizing: border-box;
}

button {
  background-color: #1D5A87;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover{
  background-color: #0C304D;
  color:white;
}

label {
  color: #555;
}

.container.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cancelbtn {
  background-color: #f44336;
  padding: 20px 20px;
  border: none;
  cursor: pointer;
  width: 50%;
}

.psw {
  float: right;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
@media screen and (max-width: 480px) {
  form {
    width: 100%;
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
  }

  .cancelbtn,
  .signInbtn {
    width: 100%;
  }
}
