/* Estilos para el encabezado */
.navbar {
    background-color:#132A3E !important; 
  }
  
  .nav-item:hover{
    border-bottom: 1px  white solid;
    transition-duration: 1s;
   border-top: solid 1px white;
  }

  .navbar-collapse{
    display: flex;
    justify-content: center;
  }

  .navbar-nav{
    display: flex;
    align-items: center;
  }

  .logoTitle{
    display: flex;
    align-items: center;
  }
  
  /*#language-dropdown{
    background-color: white;
    border-color: white; 
  }
*/
  .dropdown-menu{
    background-color:#1d5a87 !important; 
  }
  
  .dropdown-item:hover{
    background-color: rgb(12, 48, 77)!important;
  }

  /* .nav-link:hover{
    color:#F8F617 !important;
  } */

  .signInUp{
    display: flex;
    align-items: center;
  }

  .signInBtn{
    background-color: white;
    color: black;
    border-radius: 5px;   
    padding: 10px 10px;
  }

  .signInBtn:hover{
    background-color: rgb(12, 48, 77);
    transition-duration: 0.2s;
  }
  
  .signUpBtn{
    background-color: white;
    color: black;
    border-radius: 5px;   
    padding: 10px 10px;
  }

  .signUpBtn:hover{
    background-color: rgb(12, 48, 77);
    transition-duration: 0.2s;
  }
  
  