h4 {
  font-size: 1.7rem;
  font-weight:600;
}

.answer{
  font-size: 1.3rem;
}


.faqsLegalization {
  background-image: url('./ukraine.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.quest-container {
  width: 90vw;
  margin: 3rem auto;
  border-radius: 0.5rem;
  padding: 2.5rem 2rem;
  max-width: 1200px;
  display: grid;
  gap: 2rem 4rem;

}
/* .quest-container h3 {
  line-height: 1.2;
  font-weight: 500;
} */
.section{
  width: 100vw;
  margin: 0 auto;
  max-width: 1170px;
}

.questions {
  background-color: white;
  padding: 1rem 2.5rem;
  border: 2px solid #1D5A87;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  gap: 2rem 2rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.questions p {
  color: hsl(207, 83%, 5%);
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.questions header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.questions header h4 {
 margin-bottom: 0;
}
.btn {
 background-color: #DEF2FF;
 color: black;
 border-color: #1D5A87;
 cursor: pointer;
 width: 3rem;
 height: 3rem;
 display: flex;
 align-items: center;
 justify-content: space-around;
 border-radius: 50%;
}

.btn:hover{
  background-color: #1D5A87;
  color: #F8F617  ;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  .quest-container {
    padding: 1.5rem 1rem;
  }
  .questions {
    padding: 1rem 1.5rem;
  }
}


