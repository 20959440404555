.text {
    text-align: center; 
    position: absolute; 
    top: 45%; 
    left: 50%;
    transform: translate(-50%, -50%); 
    font-size: 80px;
    color: white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; 
  }
  