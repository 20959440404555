/* Add this CSS to your stylesheet or component styles */
.bodyIn{
  height: 90vh;
  width: 100%;
  background-image: url("./result2.png");
  background-size: cover;
  display: flex;
}


form {
  border: 1px solid #ccc;
  width: 500px;
  margin:auto;
  padding: 20px;
  background-color: white;
  border-radius: 0.5rem;
}

.in-container {
  text-align: center;
}

h1 {
  color: black;
}

.create{
  color: black;
}

label {
  display: block;
  margin-top: 10px;
  color: #555;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #1D5A87;
}

input[type="checkbox"] {
  margin-bottom: 15px;
}

a {
  text-decoration: none;
  color: dodgerblue;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

/* .cancelbtn {
  width: 50%;
  background-color: #f44336;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  float: left;
} */

/* .cancelbtn:hover {
  background-color: #45a049;
} */

.signupbtn {
  width: 100%;
  background-color: #1D5A87;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  float: right;
}

/* .signupbtn:hover {
  background-color: #45a049;
} */

/* Styles for screens with a maximum width of 480px (small mobile devices) */
@media screen and (max-width: 480px) {
  form {
    width: 100%;
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
  }

  .cancelbtn,
  .signupbtn {
    width: 100%;
  }
}





